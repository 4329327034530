import { Carousel } from "@mantine/carousel";
import {
  Box,
  Button,
  Center,
  Container,
  FileInput,
  Flex,
  Group,
  Image,
  LoadingOverlay,
  Menu,
  Modal,
  Paper,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Tooltip,
  createStyles,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { showNotification } from "@mantine/notifications";
import React, {
  Dispatch,
  SetStateAction,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { fetchCurrentChapter } from "../../../features/UserSubject/TeacherSubjectSlice";
import {
  AddLessonPlans,
  AddNotes,
  AddTheorytoUserTopic,
  AddWorksheets,
  CreateNewUserTopic,
  DeleteUserTopic,
} from "../../../features/UserSubject/chapterDataSlice";
import { FileUpload } from "../../../features/fileUpload/FileUpload";
import { removeChapterWorksheet } from "../../../features/userChapter/userChapterSlice";
import { AppDispatch } from "../../../store/ReduxStore";
import { UpdateType, chapter } from "../../../store/chapterSlice";
import { isLoading } from "../../../store/loadingStateSlice";
import { WebAppEvents } from "../../../utilities/Mixpanel/AnalyticeEventWebApp";
import { Mixpanel } from "../../../utilities/Mixpanel/MixpanelHelper";
import { IconDown, IconThreeDots } from "../../_Icons/CustonIcons";
import { UserType } from "../../AdminPage/DashBoard/InstituteBatchesSection";
import SunEditor from "suneditor-react";
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  image,
  lineHeight,
  link,
  list,
  math,
  paragraphStyle,
  table,
  template,
  textStyle,
} from "suneditor/src/plugins";
import { IconArrowDown } from "@tabler/icons";

import { TrailTexture } from "@react-three/drei";
import { ChapterContentPageSection } from "../../../pages/_New/ChapterContentPage";
import { getFileExtension } from "../../../utilities/HelperFunctions";
const PdfViewer = React.lazy(() => import("../FileUploadBox"));

const chapterActions = chapter.actions;

const useStyles = createStyles((theme) => ({
  headerSearch2: {
    textAlign: "left",
    borderRadius: 10,
    padding: 10,
    display: "flex",
    width: "100%",
  },
}));
interface FileTypeProps {
  fileName: string;
  fileType: string;
  createdAt: string;
}

interface UploadResourcesProps {
  chapter: SingleChapter;
  userType: UserType;
  setloading: (val: boolean) => void;
  isLoading: boolean;
  setLoadingData: (s: boolean) => void;
  topics: SingleTopic[];
  cardVisible?: boolean;
  subjectId: string;
  userChapterId: string;
  setContentPageSection: Dispatch<SetStateAction<ChapterContentPageSection>>;
  setCurrentChapterTopics: Dispatch<SetStateAction<SingleTopic[]>>;
}
export default function UploadResources(props: UploadResourcesProps) {
  const { theme } = useStyles();
  const navigate = useNavigate();
  const cardRef = useRef(null);
  const isLg = useMediaQuery(`(max-width: ${theme.breakpoints.lg}px)`);
  const isMd = useMediaQuery(`(max-width: ${theme.breakpoints.md}px)`);
  const [isPdfModalOpened, setIsPdfModalOpened] = useState<boolean>(false);
  const [
    isPersonalizedContentModalOpened,
    setIsPersonalizedContentModalOpened,
  ] = useState<boolean>(false);
  const [editContent, setEditContent] = useState<boolean>(false);
  const [openAddContentModal, setOpenAddContentModal] =
    useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("personalized");
  const [viewFiles, setViewFiles] = useState(true);
  const [file, setFile] = useState<File | null>(null);
  const [selectedFile, setSelectedFile] = useState<{
    fileType: string;
    fileName: string;
    url: string;
  }>();

  const [currentPdf, setCurrentPdf] = useState<string | null>(null);
  const [selectedType, setSelectedType] = useState<UpdateType | "ALL">("ALL");

  const fileRef = useRef<any>();
  const [switchPlus, setSwitchPlus] = useState<boolean>(false);

  const [updateType, setUpdate] = useState<UpdateType>();
  const [deleteResourceModalOpen, setDeleteResourcesModalOpen] =
    useState(false);
  const [fileToBeDeletedUrl, setFileToBeDeletedUrl] = useState("");
  const [fileToBeDeletedName, setFileToBeDeletedName] = useState("");
  const [editorContent, setEditorContent] = useState<string>("");

  const [topicIdTopicNameMap, setTopicIdTopicNameMap] = useState<
    Map<string, string>
  >(new Map());

  const [topicIdTopicTheoryMap, setTopicIdTopicTheoryMap] = useState<
    Map<string, string>
  >(new Map());
  const [selectedTopicId, setSelectedTopicId] = useState<string>();
  const [selectedTopicToViewContent, setSelectedTopicToViewContent] =
    useState<string>();
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [deleteTopicModal, setDeleteTopicModal] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const fetchChapter = async (chapter_id: string) => {
    fetchCurrentChapter({ chapter_id: chapter_id })
      .then((data: any) => {
        props?.setLoadingData(false);
        dispatch(chapterActions.setCurrentChapter(data));
      })
      .catch((error: any) => {
        props?.setLoadingData(false);
        console.log(error);
      });
  };
  

  useEffect(() => {
    if (props.topics.length > 0) {
      const topicIdTopicName = new Map(topicIdTopicNameMap);
      const topicIdTopicTheory = new Map(topicIdTopicTheoryMap);

      props.topics.forEach((topic: any) => {
        if (!topicIdTopicName.has(topic._id)) {
          topicIdTopicName.set("", "All Topics");
          topicIdTopicName.set(topic._id, topic.name);
        }
        if (!topicIdTopicTheory.has(topic._id)) {
          topicIdTopicTheory.set(topic._id, topic.theoryUrl);
        }
      });

      setTopicIdTopicNameMap(topicIdTopicName);
      setTopicIdTopicTheoryMap(topicIdTopicTheory);
    }
  }, [props.topics]);

  useEffect(() => {
    setSwitchPlus(false);
  }, [selectedType]);

  function onNoteUpload(name: string, url: string) {
    if (props.chapter._id) {
      AddNotes({ id: props.chapter._id, fileName: name, url })
        .then((x: any) => {
          props.setloading(true);
          props.setLoadingData(true);
          setFile(null);
          setSelectedType(UpdateType.CN);
          dispatch(
            chapterActions.updateCurrentChapter({
              type: UpdateType.CN,
              data: x,
            })
          );
        })
        .catch((e) => {
          props.setloading(false);
          props.setLoadingData(false);
          setFile(null);

          console.log(e);
        });
      Mixpanel.track(
        WebAppEvents.TEACHER_APP_LEARN_PAGE_NOTES_SECTION_FILE_ADDED
      );
    }
  }
  function OnLessonPlanUplaod(name: string, url: string) {
    AddLessonPlans({ id: props.chapter._id, fileName: name, url })
      .then((x: any) => {
        props.setloading(true);
        props.setLoadingData(true);
        setSelectedType(UpdateType.CLP);
        Mixpanel.track(
          WebAppEvents.TEACHER_APP_LEARN_PAGE_LESSON_PLAN_SECTION_FILE_ADDED
        );
        dispatch(
          chapterActions.updateCurrentChapter({
            type: UpdateType.CLP,
            data: x,
          })
        );
        setFile(null);
      })
      .catch((e) => {
        props.setloading(false);
        props.setLoadingData(false);
        setFile(null);

        console.log(e);
      });
  }
  function OnWorksheetUpload(name: string, url: string) {
    if (props.chapter._id) {
      AddWorksheets({ id: props.chapter._id, fileName: name, url })
        .then((x: any) => {
          props.setloading(true);
          props.setLoadingData(true);
          setSelectedType(UpdateType.CW);
          dispatch(
            chapterActions.updateCurrentChapter({
              type: UpdateType.CW,
              data: x,
            })
          );
          setFile(null);
        })
        .catch((e) => {
          props.setloading(false);
          props.setLoadingData(false);
          setFile(null);

          console.log(e);
        });
      Mixpanel.track(
        WebAppEvents.TEACHER_APP_PRACTICE_PAGE_WORKSHEET_SECTION_FILE_ADDED
      );
    }
  }

  const uploadButtonCLickHandler = (type: UpdateType) => {
    if (type === UpdateType.CN) {
      Mixpanel.track(
        WebAppEvents.TEACHER_APP_LEARN_PAGE_NOTES_SECTION_BROWSE_CLICKED
      );
    } else if (type === UpdateType.CLP) {
      Mixpanel.track(
        WebAppEvents.TEACHER_APP_LEARN_PAGE_LESSON_PLAN_SECTION_BROWSE_CLICKED
      );
    } else if (type === UpdateType.CW) {
      Mixpanel.track(
        WebAppEvents.TEACHER_APP_PRACTICE_PAGE_WORKSHEET_SECTION_BROWSE_CLICKED
      );
    }
    fileRef.current.click();
    setUpdate(type);
  };

  useEffect(() => {
    setCurrentPdf(null);
  }, [selectedType]);

  useEffect(() => {
    if (file) {
      setIsPdfModalOpened(false);
      if (file && file.size > 100 * 1024 * 1024) {
        showNotification({
          message: "File size should be less than 100mb",
        });
      } else if (
        (file && file.type === "application/pdf") ||
        file.type === "application/vnd.ms-powerpoint" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.presentationml.presentation" ||
        file.type === "application/msword" ||
        file.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        props.setloading(true);
        props?.setLoadingData(true);

        FileUpload({ file })
          .then((x) => {
            setIsPdfModalOpened(false);
            if (updateType === UpdateType.CN) {
              onNoteUpload(file.name, x.url);
            } else if (updateType === UpdateType.CLP) {
              OnLessonPlanUplaod(file.name, x.url);
            } else if (updateType === UpdateType.CW) {
              OnWorksheetUpload(file.name, x.url);
            }
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        showNotification({
          message: "Please select a PDF file.",
        });
      }
    }
  }, [file]);

  const originalData = props.chapter.chapterLessonPlan.map((x) => {
    return {
      ...x,
      fileType: "LESSON PLAN",
    };
  });

  originalData.push(
    ...props.chapter.chapterNotes.map((x) => {
      return {
        ...x,
        fileType: "NOTES",
      };
    })
  );
  originalData.push(
    ...props.chapter.chapterWorksheets.map((x) => {
      return {
        ...x,
        fileType: "WORKSHEETS",
      };
    })
  );

  const allData = originalData.filter((x) => {
    if (selectedType === "ALL") return x;
    else {
      if (selectedType === UpdateType.CLP && x.fileType === "LESSON PLAN") {
        return x;
      } else if (selectedType === UpdateType.CN && x.fileType === "NOTES") {
        return x;
      } else if (
        selectedType === UpdateType.CW &&
        x.fileType === "WORKSHEETS"
      ) {
        return x;
      }
    }
  });


  const carouselScrollRef = useRef<any>(null);
  useEffect(() => {
    if (carouselScrollRef && carouselScrollRef.current)
      carouselScrollRef.current.scrollTo(0, 0);
  }, [selectedType]);

  const handleDeleteTopic = () => {
    const filteredTopics = props.topics.filter((x)=>x._id !== selectedTopicToViewContent)
    props.setCurrentChapterTopics(filteredTopics)
    if (selectedTopicToViewContent?.startsWith("UTOP")) {
      DeleteUserTopic({
        id: selectedTopicToViewContent || "",
      })
        .then((x: any) => {
          setIsLoading(false);
          setSelectedTopicToViewContent("")
          setDeleteTopicModal(false);
          setTopicIdTopicNameMap((prev) => {
            const newMap = new Map(prev);
            newMap.delete(selectedTopicToViewContent);
            return newMap;
          });
          showNotification({
            message: "Content deleted successfully",
          });
          setSelectedTopicToViewContent("")
          setIsPersonalizedContentModalOpened(false);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      showNotification({
        message:
          "You do not have access to delete this content as it is provided by Vignam. Please create your own topic or edit the existing topic before attempting to delete it.",
      });
    }
  };

  const submitPersonalizedContent = () => {
    setTopicIdTopicTheoryMap((prev) => {
      const newMap = new Map(prev);
      if (!newMap.has(selectedTopicId!)) {
        newMap.set(selectedTopicId!, editorContent);
      }
      return newMap;
    });

    setIsLoading(true);
    AddTheorytoUserTopic({
      id: selectedTopicId || "",
      chapterId: props.chapter._id,
      theory: editorContent,
    })
      .then((x: any) => {
        setIsLoading(false);
        showNotification({
          message: "Content added successfully",
        });
        setIsPersonalizedContentModalOpened(false);
        setEditorContent("")
        setSelectedTopicId("")
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const editPersonalizedContent = () => {
    setIsLoading(true);
    AddTheorytoUserTopic({
      id: selectedTopicToViewContent || "",
      chapterId: props.chapter._id,
      theory: editorContent,
    })
      .then((x: any) => {
        // Delte entry if id starts with TOP and add response that come as UTOP
        if(selectedTopicToViewContent?.startsWith("TOP")){
          setTopicIdTopicNameMap((prev) => {
            const newMap = new Map(prev);
            newMap.delete(selectedTopicToViewContent!);
            newMap.set(x._id, x.name);
            return newMap;
          });
          setTopicIdTopicTheoryMap((prev) => {
            const newMap = new Map(prev);
            newMap.delete(selectedTopicToViewContent!);
            newMap.set(x._id, x.theoryUrl);
            return newMap;
          });
        } else {
          setTopicIdTopicTheoryMap((prev) => {
            const newMap = new Map(prev);
            if(newMap.has(x._id)){
              newMap.set(x._id, x.theoryUrl);
            }
            return newMap;
          });
        }
        setSelectedTopicToViewContent(x._id)
        const filteredTopics = props.topics.filter((x)=>x._id !== selectedTopicToViewContent)
        props.setCurrentChapterTopics([...filteredTopics,x])
        setEditorContent("")
        setSelectedTopicId("")
        setIsLoading(false);
        showNotification({
          message: "Content edited successfully",
        });
        setIsPersonalizedContentModalOpened(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      {isMd && (
        <>
        {
          props.userType === UserType.OTHERS &&
          <Image
            src={require("../../../assets/addContent.png")}
            alt="Empty"
            width={100}
            sx={{
              cursor: "pointer",
              position: "fixed",
              bottom: "50px",
              right: "10px",
              zIndex: 111,
            }}
            onClick={() => {
              Mixpanel.track(
                WebAppEvents.TEACHER_APP_LEARN_PAGE_ADD_FILES_BUTTON_CLICKED
              );
              setOpenAddContentModal(true);
            }}
          />
        }
        </>
      )}
      {originalData.length === 0 && (
        <Center
          style={{
            height: "100%",
            width: "100%",
          }}
        >
          <Stack justify="center" align="center">
            <img
              src={require("../../../assets/EmptyResources.png")}
              height="132px"
              width="132px"
            />
            <Text color="#B5B5B5" fz={15}>
              Your files can be viewed in this section
            </Text>
            {props.userType == UserType.OTHERS && (
              <Button
                size="md"
                bg="#4B65F6"
                onClick={() => {
                  setOpenAddContentModal(true);
                }}
              >
                Add Resources
              </Button>
            )}
          </Stack>
        </Center>
      )}
      <FileInput
        accept=".pdf,.ppt,.pptx,.doc,.docx"
        ref={fileRef}
        value={file}
        onChange={setFile}
        display="none"
      />
      {originalData.length !== 0 && (
        <div
          style={{
            width: "100%",
            marginBottom: "30px",
          }}
        >
          {viewFiles ? (
            <StyledContainer fluid={true} mt={isMd ? 40 : 10}>
              <StyledHeader py={isMd ? 10 : 10} size="lg" px={isMd ? 10 : 5}>
                <Menu
                  styles={{
                    dropdown: {
                      marginLeft: 15,
                    },
                  }}
                >
                  <Menu.Target>
                    <Button
                      radius={8}
                      ta={"left"}
                      variant="subtle"
                      rightIcon={<IconDown />}
                      c="#000000"
                      fw={600}
                      onClick={() => {
                        Mixpanel.track(
                          WebAppEvents.TEACHER_APP_LEARN_PAGE_RESOURCES_SECTION_FILTER_DROPDOWN_CLICKED
                        );
                      }}
                      fz={18}
                    >
                      {selectedType === "ALL" && "All files"}
                      {selectedType === UpdateType.CLP && "Lesson Plan"}
                      {selectedType === UpdateType.CN && "Notes"}
                      {selectedType === UpdateType.CW && "Worksheets"}
                      {/* </span> */}
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Item
                      onClick={() => {
                        Mixpanel.track(
                          WebAppEvents.TEACHER_APP_PRACTICE_PAGE_RESOURCES_FILTER_CLICKED,
                          {
                            type: "ALL",
                          }
                        );
                        if (selectedType !== "ALL") setSwitchPlus(true);
                        setSelectedType("ALL");
                      }}
                    >
                      <Text>All Files</Text>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        Mixpanel.track(
                          WebAppEvents.TEACHER_APP_PRACTICE_PAGE_RESOURCES_FILTER_CLICKED,
                          {
                            type: "Lesson Plan",
                          }
                        );
                        if (selectedType !== UpdateType.CLP)
                          setSwitchPlus(true);

                        setSelectedType(UpdateType.CLP);
                      }}
                    >
                      <Text>Lesson Plan</Text>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        Mixpanel.track(
                          WebAppEvents.TEACHER_APP_PRACTICE_PAGE_RESOURCES_FILTER_CLICKED,
                          {
                            type: "Notes",
                          }
                        );
                        if (selectedType !== UpdateType.CN) setSwitchPlus(true);

                        setSelectedType(UpdateType.CN);
                      }}
                    >
                      <Text>Notes</Text>
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        Mixpanel.track(
                          WebAppEvents.TEACHER_APP_PRACTICE_PAGE_RESOURCES_FILTER_CLICKED,
                          {
                            type: "Worksheet",
                          }
                        );
                        if (selectedType !== UpdateType.CW) setSwitchPlus(true);

                        setSelectedType(UpdateType.CW);
                      }}
                    >
                      <Text>Worksheet</Text>
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
                {props.userType == UserType.OTHERS && allData.length !== 0 && !isMd && (
                  <Button
                    bg="#4B65F6"
                    size={isMd ? "xs" : "md"}
                    onClick={() => {
                      Mixpanel.track(
                        WebAppEvents.TEACHER_APP_LEARN_PAGE_ADD_FILES_BUTTON_CLICKED
                      );
                      setOpenAddContentModal(true);
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: "",
                      },
                    }}
                    style={{
                      fontSize: isMd ? 14 : 18,
                      borderRadius: "24px",
                      fontFamily: "Nunito",
                      background: "transparent",
                      color: "black",
                      padding: "9px 14px 9px 14px",
                      border: "1px solid black",
                    }}
                  >
                    Add Content
                  </Button>
                )}
              </StyledHeader>
              <Stack w={"100%"}>
                <Flex justify={"center"} align={"center"} w={"100%"} pt={20}>
                  {allData.length !== 0 && !switchPlus && !props.isLoading && (
                    <Carousel
                      withIndicators
                      height={isMd ? "30vh" : "40vh"}
                      loop
                      w={"100%"}
                      slideSize={isMd ? "30%" : "10%"}
                      style={{
                        height: "200px",
                      }}
                      align={"start"}
                      styles={{
                        control: {
                          width: 30,
                          height: 30,
                        },
                      }}
                      ref={carouselScrollRef}
                    >
                      <>
                        {allData.map((x, index) => (
                          <Carousel.Slide w={"100%"} key={index} mt={15}>
                            <Flex
                              justify={"space-between"}
                              py={"md"}
                              mx={"sm"}
                              h={"160px"}
                              w={"150px"}
                              align={"center"}
                              sx={{
                                borderRadius: "5px",
                                boxShadow: "0px 0px 20px 0px #0000001A",
                                flexDirection: "column",
                                position: "relative",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                if (x.fileType === "WORKSHEETS") {
                                  Mixpanel.track(
                                    WebAppEvents.TEACHER_APP_LEARN_PAGE_FILE_CLICKED,
                                    {
                                      type: "worksheet",
                                    }
                                  );
                                } else if (x.fileType === "NOTES") {
                                  Mixpanel.track(
                                    WebAppEvents.TEACHER_APP_LEARN_PAGE_FILE_CLICKED,
                                    {
                                      type: "notes",
                                    }
                                  );
                                } else if (x.fileType === "LESSON PLAN") {
                                  Mixpanel.track(
                                    WebAppEvents.TEACHER_APP_LEARN_PAGE_FILE_CLICKED,
                                    {
                                      type: "lesson plan",
                                    }
                                  );
                                }
                                setCurrentPdf(x.url);
                                setSelectedFile(x);
                              }}
                            >
                              <Flex
                                justify={"center"}
                                align={"center"}
                                w={"100%"}
                                direction={"column"}
                              >
                                {x.fileType === "WORKSHEETS" && (
                                  <img
                                    src={require("../../../assets/worksheetType.png")}
                                    alt="Icon"
                                    style={{
                                      width: "50px",
                                      aspectRatio: "1",
                                      height: "50px",
                                    }}
                                  />
                                )}
                                {x.fileType === "NOTES" && (
                                  <img
                                    src={require("../../../assets/notesType.png")}
                                    alt="Icon"
                                    style={{
                                      width: "50px",
                                      aspectRatio: "1",
                                      height: "50px",
                                    }}
                                  />
                                )}
                                {x.fileType === "LESSON PLAN" && (
                                  <img
                                    src={require("../../../assets/lessonType.png")}
                                    alt="Icon"
                                    style={{
                                      width: "50px",
                                      aspectRatio: "1",
                                      height: "50px",
                                    }}
                                  />
                                )}
                                {/*  */}
                                <Flex
                                  direction={"column"}
                                  py={20}
                                  gap={"xs"}
                                  align={"center"}
                                >
                                  <span
                                    style={{
                                      fontSize: "8px",
                                      background: `${
                                        x.fileType === "WORKSHEETS"
                                          ? "#FDC00F26"
                                          : x.fileType === "NOTES"
                                          ? "#11BC1826"
                                          : x.fileType === "LESSON PLAN"
                                          ? "#BC11A126"
                                          : ""
                                      }`,
                                      maxWidth: "65px",
                                      padding: "1px 5px",
                                      borderRadius: "5px",
                                      display: "flex",
                                      justifyContent: "center",
                                      border: `1px solid ${
                                        x.fileType === "WORKSHEETS"
                                          ? "#FDC00F"
                                          : x.fileType === "NOTES"
                                          ? "#11BC18"
                                          : x.fileType === "LESSON PLAN"
                                          ? "#BC11A1"
                                          : ""
                                      }`,
                                      color: `${
                                        x.fileType === "WORKSHEETS"
                                          ? "#FDC00F"
                                          : x.fileType === "NOTES"
                                          ? "#11BC18"
                                          : x.fileType === "LESSON PLAN"
                                          ? "#BC11A1"
                                          : ""
                                      }`,
                                      fontWeight: 700,
                                    }}
                                  >
                                    {x.fileType.charAt(0).toLocaleUpperCase()}
                                    {x.fileType.slice(1).toLocaleLowerCase()}
                                  </span>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Tooltip
                                      label={x.fileName}
                                      position="bottom"
                                    >
                                      <span style={{ fontSize: "14px" }}>
                                        {x.fileName.length > 15
                                          ? `${x.fileName.substring(0, 15)}...`
                                          : x.fileName}
                                      </span>
                                    </Tooltip>
                                  </div>
                                </Flex>
                                <div
                                  style={{
                                    position: "relative",
                                    bottom: "145px",
                                    left: "55px",
                                    zIndex: 3,
                                  }}
                                >
                                  {x.fileType !== "LESSON PLAN" &&
                                    props.userType == UserType.OTHERS && (
                                      <Menu
                                        styles={{
                                          dropdown: {
                                            marginLeft: 15,
                                          },
                                        }}
                                      >
                                        <Menu.Target>
                                          <Button
                                            radius={8}
                                            ta={"left"}
                                            variant="subtle"
                                            rightIcon={<IconThreeDots />}
                                            c="#000000"
                                            fw={600}
                                            onClick={(event) => {
                                              event.stopPropagation();
                                            }}
                                            fz={18}
                                            sx={{
                                              ":hover": {
                                                backgroundColor: "transparent",
                                              },
                                            }}
                                          ></Button>
                                        </Menu.Target>
                                        {x.fileType !== "LESSON PLAN" && (
                                          <StyledMenuDropdown className="menu-dropdown">
                                            <Menu.Item
                                              onClick={(event) => {
                                                event.stopPropagation();
                                              }}
                                              className="menu-item"
                                            >
                                              <Text
                                                onClick={async (event) => {
                                                  event.stopPropagation();
                                                  setFileToBeDeletedUrl(x?.url);
                                                  setFileToBeDeletedName(
                                                    x?.fileName
                                                  );
                                                  setDeleteResourcesModalOpen(
                                                    true
                                                  );
                                                }}
                                              >
                                                Delete
                                              </Text>
                                            </Menu.Item>
                                          </StyledMenuDropdown>
                                        )}
                                      </Menu>
                                    )}
                                </div>
                              </Flex>
                            </Flex>
                          </Carousel.Slide>
                        ))}
                      </>
                    </Carousel>
                  )}
                  {allData.length === 0 && (
                    <Center
                      style={{
                        height: "40vh",
                        width: "100%",
                      }}
                    >
                      <Stack justify="center" align="center">
                        <Image
                          src={require("../../../assets/EmptyResources.png")}
                          height="120px"
                          width="120px"
                        />

                        {props.userType == UserType.OTHERS && (
                          <Button
                            size="md"
                            bg="#4B65F6"
                            onClick={() => {
                              setIsPdfModalOpened(true);
                              setOpenAddContentModal(true);
                            }}
                          >
                            Add Resources
                          </Button>
                        )}
                      </Stack>
                    </Center>
                  )}
                </Flex>
              </Stack>
            </StyledContainer>
          ) : (
            <Flex
              sx={{
                justifyContent: "center",
                flexDirection: "column",
                height: "70vh",
                alignItems: "center",
                gap: "20px",
                width: "100%",
              }}
            >
              <Image
                src={require("../../../assets/emptyFolder.png")}
                alt="Empty"
                style={{ height: "170px", width: "290px" }}
              />
              {props.userType == UserType.OTHERS && (
                <Button
                  bg={"#4B65F6"}
                  onClick={() => setIsPdfModalOpened(true)}
                >
                  Add Resources
                </Button>
              )}
            </Flex>
          )}
        </div>
      )}
      {props.topics.length !== 0 && (
        <>
          <Flex
            gap={10}
            align={"center"}
            pt={"1rem"}
            sx={{
              position: "sticky",
              top: 110,
              backgroundColor: "white",
              zIndex: 4,
            }}
          >
            <Select
              placeholder="Select Topic"
              data={Array.from(topicIdTopicNameMap.entries()).map(
                ([_id, name]) => ({
                  label: name,
                  value: _id,
                })
              )}
              onChange={(value: string) => setSelectedTopicToViewContent(value)}
              sx={(theme) => ({
                border: "none",
                paddingRight: "1rem",
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[7]
                    : theme.white,
                borderRadius: theme.radius.sm,
                "& .mantine-Select-input": {
                  border: "none",
                  paddingRight: "2.5rem",
                },
                "& .mantine-Select-rightSection": {
                  right: 0,
                  top: "50%",
                  transform: "translateY(-50%)",
                },
                "& .mantine-Select-wrapper": {
                  border: "none",
                },
              })}
              rightSection={
                <Image
                  src={require("../../../assets/arrowdown.png")}
                  alt="Empty"
                  width={12}
                />
              }
              rightSectionWidth={40}
              dropdownPosition="bottom"
            />
            {props.userType == UserType.OTHERS &&
              selectedTopicToViewContent && (
                <>
                  <Image
                    src={require("../../../assets/editIcon.png")}
                    alt="Empty"
                    width={25}
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setEditContent(true);
                      selectedTopicToViewContent &&
                        setIsPersonalizedContentModalOpened(true);
                      selectedTopicToViewContent &&
                        setEditorContent(
                          topicIdTopicTheoryMap.get(
                            selectedTopicToViewContent!
                          ) || ""
                        );
                    }}
                  />
                  <Image
                    src={require("../../../assets/deleteIcon.png")}
                    alt="Empty"
                    width={25}
                    sx={{ cursor: "pointer" }}
                    onClick={() =>
                      selectedTopicToViewContent && setDeleteTopicModal(true)
                    }
                  />
                </>
              )}

            {props.cardVisible && (
              <Flex ml={"1rem"} gap={10}>
                <Image
                  src={require("../../../assets/simulationicon1.png")}
                  onClick={() =>
                    props.setContentPageSection(
                      ChapterContentPageSection.SIMULATIONS
                    )
                  }
                  alt="Empty"
                  width={45}
                  sx={{ cursor: "pointer" }}
                />
                <Image
                  src={require("../../../assets/videoicon1.png")}
                  onClick={() =>
                    props.setContentPageSection(
                      ChapterContentPageSection.VIDEOS
                    )
                  }
                  alt="Empty"
                  width={45}
                  sx={{ cursor: "pointer" }}
                />
                <Image
                  src={require("../../../assets/questionbankicon1.png")}
                  onClick={() =>
                    props.setContentPageSection(
                      ChapterContentPageSection.QUESTIONBANK
                    )
                  }
                  alt="Empty"
                  width={45}
                  sx={{ cursor: "pointer" }}
                />
              </Flex>
            )}
          </Flex>
          {props.topics.length > 0 && !selectedTopicToViewContent ? (
            props.topics.map((topic: any) => (
              <Stack mt={"1rem"} pl={"0.5rem"} pr={"1rem"} >
                <Text fz={20} fw={700} sx={{ fontFamily: "Nunito" }}>
                  {topic.name}
                </Text>

                <Text
                  fz={18}
                  fw={400}
                  mt={"0.4rem"}
                  sx={{
                    fontFamily: "Nunito",
                    whiteSpace: "pre-line",
                    width: isMd?"100vw":"78vw",
                    overflowX: "auto",
                  }}
                  component="div"
                  dangerouslySetInnerHTML={{ __html: topic.theoryUrl }}
                />
              </Stack>
            ))
          ) : (
            <Stack mt={"1rem"} px={"0.5rem"}>
              <Text fz={20} fw={700} sx={{ fontFamily: "Nunito" }}>
                {topicIdTopicNameMap.get(selectedTopicToViewContent!)}
              </Text>

              <Text
                fz={18}
                fw={400}
                mt={"0.4rem"}
                sx={{ fontFamily: "Nunito" }}
                component="div"
                dangerouslySetInnerHTML={{
                  __html:
                    topicIdTopicTheoryMap.get(selectedTopicToViewContent!) ||
                    "",
                }}
              />
            </Stack>
          )}
        </>
      )}
      <Modal
        opened={openAddContentModal}
        size={"sm"}
        onClose={() => setOpenAddContentModal(false)}
        title="Add Content"
        centered
      >
        <Select
          label="Topic Name"
          placeholder="Topic Name"
          data={Array.from(topicIdTopicNameMap.entries())
            .filter(([_id, name]) => _id)
            .map(([_id, name]) => ({
              label: name,
              value: _id,
            }))}
          searchable
          creatable
          onChange={(value: string) => setSelectedTopicId(value)}
          getCreateLabel={(query) => `+ Create ${query}`}
          onCreate={(query) => {
            const item = { value: query, label: query };
            setTopicIdTopicNameMap((prev) => {
              const newMap = new Map(prev);
              if (!topicIdTopicNameMap.has(query)) {
                newMap.set(query, query);
              }
              return newMap;
            });
            return item;
          }}
          sx={{ marginBottom: "1rem" }}
        />
        <Flex justify={"space-between"} style={{ marginBottom: "1rem" }}>
          <Paper
            withBorder
            radius="md"
            p="auto"
            w={"10rem"}
            h={"12rem"}
            onClick={() => setSelectedOption("personalized")}
            style={{
              cursor: "pointer",
              backgroundColor:
                selectedOption === "personalized" ? "#EFF1FE" : "white",
              borderColor:
                selectedOption === "personalized" ? "#7C90FF" : "#808080",
            }}
          >
            <Flex
              h={"100%"}
              direction={"column"}
              align="center"
              justify="center"
              m={"auto"}
            >
              <Image
                src={require("../../../assets/textIcon.png")}
                alt="personalized content"
                width={40}
              />
              <Text
                fw={400}
                fz={"sm"}
                align="center"
                sx={{ marginTop: "0.5rem" }}
              >
                Type your own personalized content
              </Text>
            </Flex>
          </Paper>
          <Paper
            withBorder
            radius="md"
            p="md"
            w={"10rem"}
            h={"12rem"}
            onClick={() => setSelectedOption("pdf")}
            style={{
              cursor: "pointer",
              backgroundColor: selectedOption === "pdf" ? "#EFF1FE" : "white",
              borderColor: selectedOption === "pdf" ? "#7C90FF" : "#808080",
            }}
          >
            <Flex
              h={"100%"}
              direction={"column"}
              align="center"
              justify="center"
              m={"auto"}
            >
              <Image
                src={require("../../../assets/pdfIcon.png")}
                alt="upload pdf"
                width={40}
              />
              <Text
                fw={400}
                fz={"sm"}
                align="center"
                sx={{ marginTop: "0.5rem" }}
              >
                Upload File
              </Text>
            </Flex>
          </Paper>
        </Flex>
        <Flex align={"center"} justify={"end"} gap={5}>
          <Button
            variant="outline"
            radius={"lg"}
            sx={{ borderColor: "#808080", color: "black" }}
            onClick={() => setOpenAddContentModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            bg={"#4B65F6"}
            radius={"lg"}
            disabled={!selectedTopicId}
            onClick={() => {
              if (
                selectedTopicId === topicIdTopicNameMap.get(selectedTopicId!)
              ) {
                CreateNewUserTopic({
                  name: topicIdTopicNameMap.get(selectedTopicId!) || "",
                  chapterId: props.chapter._id,
                })
                  .then((x: any) => {
                    topicIdTopicNameMap.delete(selectedTopicId!);
                    setTopicIdTopicNameMap((prev) => {
                      const newMap = new Map(prev);
                      if (!newMap.has(x.userTopic._id)) {
                        newMap.set(x.userTopic._id, x.userTopic.name);
                      }
                      return newMap;
                    });
                    setSelectedTopicId(x.userTopic._id);
                    showNotification({
                      message: "Topic created successfully",
                    });
                    setEditorContent("");
                    setIsLoading(false);
                  })
                  .catch((e) => {
                    console.log("Error  is : ", e);
                  });
              }

              if (selectedOption == "pdf") {
                setIsPdfModalOpened(true);
                setOpenAddContentModal(false);
              } else {
                setIsPersonalizedContentModalOpened(true);
                setOpenAddContentModal(false);
              }
            }}
          >
            + Add Content
          </Button>
        </Flex>
      </Modal>

      <Modal
        opened={isPdfModalOpened}
        onClose={() => setIsPdfModalOpened(false)}
        title="Choose file type to add"
        centered
        radius={10}
      >
        <Flex sx={{ flexDirection: "column", gap: "15px" }}>
          <Button
            p={"10px"}
            sx={{ border: "1px solid #4B65F6" }}
            size="lg"
            variant="outline"
            c="#4B65F6"
            onClick={() => {
              uploadButtonCLickHandler(UpdateType.CN);
            }}
          >
            Notes
          </Button>
          <Button
            p={"10px"}
            sx={{ border: "1px solid #4B65F6" }}
            size="lg"
            variant="outline"
            c="#4B65F6"
            onClick={() => {
              uploadButtonCLickHandler(UpdateType.CW);
            }}
          >
            Worksheet
          </Button>
          <Button
            p={"10px"}
            sx={{ border: "1px solid #4B65F6" }}
            size="lg"
            variant="outline"
            c="#4B65F6"
            onClick={() => {
              uploadButtonCLickHandler(UpdateType.CLP);
            }}
          >
            Lesson Plan
          </Button>
        </Flex>
      </Modal>
      <Modal
        opened={isPersonalizedContentModalOpened}
        onClose={() => setIsPersonalizedContentModalOpened(false)}
        title={`${
          editContent ? "Edit Personalized Content" : "Add Personalized Content"
        }`}
        centered
        size={"xl"}
        radius={10}
      >
        <SunEditor
          autoFocus={true}
          lang="en"
          setOptions={{
            showPathLabel: false,
            minHeight: "40vh",
            maxHeight: "40vh",
            width: "100%",
            placeholder: "Enter your text here!!!",
            plugins: [
              align,
              font,
              fontColor,
              fontSize,
              formatBlock,
              hiliteColor,
              horizontalRule,
              lineHeight,
              list,
              paragraphStyle,
              table,
              template,
              textStyle,
              image,
              link,
            ],
            buttonList: [
              ["undo", "redo"],
              ["font", "fontSize", "formatBlock"],
              ["paragraphStyle"],
              [
                "bold",
                "underline",
                "italic",
                "strike",
                "subscript",
                "superscript",
              ],
              ["fontColor", "hiliteColor"],
              ["removeFormat"],
              "/",
              ["outdent", "indent"],
              ["align", "horizontalRule", "list", "lineHeight"],
              ["table", "link", "image"],
            ],
            formats: ["p", "div", "h1", "h2", "h3", "h4", "h5", "h6"],
            font: [
              "Arial",
              "Calibri",
              "Comic Sans",
              "Courier",
              "Garamond",
              "Georgia",
              "Impact",
              "Lucida Console",
              "Palatino Linotype",
              "Segoe UI",
              "Tahoma",
              "Times New Roman",
              "Trebuchet MS",
            ],
            imageResizing: true,
          }}
          onChange={setEditorContent}
          setContents={editorContent}
        />
        <Flex align={"center"} justify={"end"} gap={5}>
          <Button
            variant="outline"
            radius={"lg"}
            sx={{ borderColor: "#808080", color: "black" }}
            onClick={() => setIsPersonalizedContentModalOpened(false)}
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            bg={"#4B65F6"}
            radius={"lg"}
            onClick={
              editContent ? editPersonalizedContent : submitPersonalizedContent
            }
            disabled={!editorContent}
          >
            Submit
          </Button>
        </Flex>
      </Modal>

      {/* {currentPdf !== null && (
        <Box sx={{zIndex:1111111}} >
        <React.Suspense fallback={<div>Loading...</div>}>
          <PdfViewer url={selectedFile?.url || ""} />
        </React.Suspense>
        </Box>
      )} */}

      <Modal
        opened={deleteResourceModalOpen}
        onClose={() => setDeleteResourcesModalOpen(false)}
        centered
        title="Delete Resource"
        styles={{
          title: {
            fontWeight: 700,
          },
        }}
      >
        <Content>Are you sure you want to delete </Content>
        <ButtonContainer>
          <CancelButton
            onClick={() => {
              setDeleteResourcesModalOpen(false);
            }}
            disabled={fileToBeDeletedUrl?.length === 0}
          >
            Cancel
          </CancelButton>
          <SubmitButton
            disabled={fileToBeDeletedName?.length === 0}
            onClick={async () => {
              props?.setLoadingData(true);
              await removeChapterWorksheet(
                props?.chapter?._id,
                fileToBeDeletedName,
                fileToBeDeletedUrl
              );
              setDeleteResourcesModalOpen(false);
              fetchChapter(props?.chapter?._id);
            }}
          >
            Yes
          </SubmitButton>
        </ButtonContainer>
      </Modal>
      <Modal
        opened={currentPdf !== null}
        onClose={() => {
          setCurrentPdf(null);
        }}
        fullScreen
        zIndex={1111}
      >
        <React.Suspense fallback={<div>Loading...</div>}>
          <PdfViewer url={selectedFile?.url || ""} />
        </React.Suspense>
      </Modal>
      <Modal
        opened={deleteTopicModal}
        onClose={() => setDeleteTopicModal(false)}
        centered
        title="Delete Resource"
        styles={{
          title: {
            fontWeight: 700,
          },
        }}
      >
        <Content>
          Are you sure you want to delete{" "}
          {topicIdTopicNameMap.get(selectedTopicId!)} topic?
        </Content>
        <ButtonContainer>
          <CancelButton
            onClick={() => {
              setDeleteTopicModal(false);
            }}
          >
            Cancel
          </CancelButton>
          <SubmitButton onClick={handleDeleteTopic}>Yes</SubmitButton>
        </ButtonContainer>
      </Modal>
    </>
  );
}

const StyledContainer = styled(Container)`
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const StyledHeader = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledMenuDropdown = styled(Menu.Dropdown)`
  top: 30px !important;
  left: 10px !important;
  background-color: white;
  .menu-item {
    padding: 0 5px;
    &:hover {
      background-color: white;
    }
  }
`;

const Content = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;
const Title = styled.span`
  font-family: "Nunito";
  font-weight: 700;
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CancelButton = styled.button`
  border: 0;
  padding: 12px 20px;
  margin: 10px;
  border-radius: 24px;
  color: white;
  font-weight: 400;
  cursor: pointer;
  background-color: #cccccc;
`;
const SubmitButton = styled.button`
  border: 0;
  padding: 12px 20px;
  margin: 10px;
  border-radius: 24px;
  color: white;
  font-weight: 400;
  background-color: #4b65f6;
  cursor: pointer;
`;
