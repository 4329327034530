

     export function generateCredentialsOnBasisOfCode(code:string|null):{email:string,password:string} {
        if(code=="kjehw324rf3h4532pinabcjwdh4sdce534"){
            return {email:"novel.pinnacle@gmail.com",password:"novel89795"}
        }
        else if(code=="vigadminabc94hjfjwehj3jfwhdfjehd"){
            return {email:"feedemo1@gmail.com",password:"feedemo197812"}
        }
        return {email:"",password:""}
    }


